
export default {
	methods: {
		filename(routeQuery) {
			const { partner1, partner2 } = this.agreement || {};
			const partner1Last = partner1?.name?.last || '';
			const partner2Last = partner2?.name?.last || '';
			let resultName = 'Separation Agreement';

			// if (routeQuery?.download === 'summary') {
			// 	resultName = partner1Last && partner2Last
			// 		? `${partner1Last}${partner1Last === partner2Last ? '' : ` ${partner2Last}`} Separation Agreement Summary`
			// 		: 'Separation Agreement Summary';
			// } else if (routeQuery?.download === 'property-schedule') { /* Released in v2.1 */
			if (routeQuery?.download === 'property-schedule') {
				resultName = partner1Last && partner2Last
					? `${partner1Last}${partner1Last === partner2Last ? '' : ` ${partner2Last}`} Property Schedule`
					: 'Property Schedule';
			} else if (partner1Last && partner2Last) {
				resultName = `${partner1Last}${partner1Last === partner2Last ? '' : ` ${partner2Last}`} Separation Agreement`;
			}

			return resultName;
		},
	},
};
