<script>
import { mapState } from 'vuex';
import Preview from '../components/Agreements/Preview.vue';
import filename from '@/mixins/filename';
import { analytics, storage } from '../firebase';

import authenticatedRequest from '@/lib/authenticatedRequest';

const additionalStyles = `
<style>
	@font-face {
		font-family: 'Open Sans', sans-serif;
		font-style: lite;
		font-weight: 300;
		src: url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
	}
	html { font-family: 'Open Sans', sans-serif; font-size: 13px; }

	.table {
		font-size: 9px;
	}

	.table-primary {
		background-color: #7EBDD8 !important;
	}
	.table-success {
		background-color: #9CB6BD !important;
	}
	.table-dark {
		background-color: #243B42 !important;
		color: #fff !important;
	}
	.table-light {
		background-color: #3F6772 !important;
		color: #fff !important;
	}
	.table-secondary{
		background-color: #678B95 !important;
		color: #fff !important;
	}

    h1 {
      string-set: chapter-title content();
    }

	#watermark {
		flow: static(watermarkflow);
		font-size: 120px;
		opacity: 0.3;
		transform: rotate(-30deg);
		text-align: center;
	}

	@page {
		@prince-overlay {
			content: flow(watermarkflow)
		}
	}

	#recitals-preview, #agreement, .no-list{
		text-align: justify;
	}

    .footer-left {
      flow: static(footer-left);
      text-align: left;
      border-top: 1pt solid black;
      height: 96px;
      padding-top: 10px;
    }

    .footer-right {
      flow: static(footer-right);
      text-align: right;
      border-top: 1pt solid black;
      height: 96px;
      padding-top: 10px;
    }

    @page {
      size: 8.5in 11in;
      margin: 96px;
	  margin-bottom: 10rem;
    }

    @page {
      @top {
        border-bottom: 1pt solid black;
      }

      @top-left {
        content: string(chapter-title);
        border-bottom: 1pt solid black;
        vertical-align: bottom;
        margin-bottom: 6px;
        padding-bottom: 6px;
      }
      @top-right {
        content: 'Page ' counter(page);
        border-bottom: 1pt solid black;
        vertical-align: bottom;
        margin-bottom: 6px;
        padding-bottom: 6px;
      }

      @bottom-left {
        padding-top: 4px;
        content: flow(footer-left);
      }

      @bottom-right {
        margin-top: 4px;
        content: flow(footer-right);
      }
	  
	}

    @page:first {
      @top-left {
        content: '';
        border-bottom: none;
      }
      @top-right {
        content: '';
        border-bottom: none;
      }
    }

    @page last {
      @bottom-left {
        content: '';
      }

      @bottom-right {
        content: '';
      }
    }

	@page noAnnotations {
      @top-left {
        content: '';
        border-bottom: none;
      }
      @top-right {
        content: '';
        border-bottom: none;
      }

	  @bottom-left {
        content: '';
      }

      @bottom-right {
        content: '';
      }
	}

	@page landscape {
      size: 8.5in 11in;
      margin: 50px;

	  @top-left {
        content: '';
        border-bottom: none;
      }
      @top-right {
        content: '';
        border-bottom: none;
      }

	  @bottom-left {
        content: '';
      }

      @bottom-right {
        content: '';
      }
    }

    .landscape {
      page: landscape;
    }
	  
	@page portrait{
		size: A4 portrait;
		margin: 20px;

		@top-left {
			content: '';
			border-bottom: none;
		}
		@top-right {
			content: '';
			border-bottom: none;
		}

		@bottom-left {
			content: '';
		}

		@bottom-right {
			content: '';
		}
	}

.portrait {
    page: portrait;
}


	.screen-hide:empty,
	.print-hide:empty {
		display: none;
		page-break-inside: avoid !important;
		page-break-after: avoid !important;
		page-break-before: avoid !important;
	}

    #signature {
      page: last;
    }

	.noAnnotations {
		page: noAnnotations;
	}

    #waiver-legal-advice {
      page: last;
    }

	/* Printstyles.css below */
	@media screen {
		.preview {
			margin: auto;
		}

		.screen-hide {
			display: none;
		}
	}

	.preview {
		width: 100%;
	}

	.height-26 {
		height: 26px;
	}

	.w-100 {
		width: 100%;
	}

	.border:not([border]), .border:not([border]) td{
		// border: 1px dashed rgb(187, 187, 187) !important;
		border:none !important;
	}

	.w-50 {
		width: 50%;
	}

	.space-left {
		margin-left: 40px;
	}
	@media print {	
		.print-hide {
			display: none;
		}
	}

	/* html, body {
		width: 210mm;
		height: 297mm; 
	} */

	#fill {
		width: 100%;
	}

	/* Avoid breaking signature page */
	.break-avoid {
		page-break-inside: avoid;
	}

	.preview li {
		margin-bottom: 12px;
		/* break-inside: avoid; */
	}

	.preview ol {
		margin-left: 1.25em;
		padding-left: 1.25em;
	}

	/* This is because we don't nest our sub lists inside of li tags */
	.preview li > ol {
		margin-top: 12px;
	}

	.preview p {
		margin-bottom: 0;
	}

	.no-list {
		list-style: none;
	}

	ol.one {
		counter-reset: one;
		list-style-type: none;
	}

	ol.one > li:before {
		position: absolute;
		counter-increment: one;
		content: counter(one, upper-latin) '.';

		margin-left: -2.5em;
		margin-right: 1.25em;
	}

	ol.two {
		counter-reset: two;
		list-style-type: none;
	}

	ol.two > li:before {
		position: absolute;
		counter-increment: two;
		content: '(' counter(two, decimal) ')';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	ol.three {
		counter-reset: three;
		list-style-type: none;
	}

	ol.three > li:before {
		position: absolute;
		counter-increment: three;
		content: '(' counter(three, lower-alpha) ')';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	ol.four,
	ol.eight {
		counter-reset: four;
		list-style-type: none;
	}

	ol.four > li:before,
	ol.eight > li:before {
		position: absolute;
		counter-increment: four;
		content: '(' counter(four, lower-roman) ')';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	ol:not(.skip).five {
		counter-reset: five;
		list-style-type: none;
	}

	ol.five > li:not(.skip):before {
		position: absolute;
		counter-increment: five;
		content: counter(five, decimal) '.';

		margin-left: -2.5em;
		margin-right: 1.5em;
	}

	ol:not(.skip).six {
		counter-reset: six;
		list-style-type: none;
	}

	ol.six > li:not(.skip):before {
		position: absolute;
		counter-increment: six;
		content: counter(five, decimal) '.' counter(six, decimal);

		margin-left: -2.5em;
		margin-right: 0.25em;
	}

	.reset-to-8 {
		counter-increment: six 7;
		margin-top: -12px;
		/* content: counter(five, decimal) '.' counter(six, decimal) '.';

		margin-left: 1.25em;
		margin-right: 0.25em; */
	}
	
	.reset-to-7{
	    counter-increment: six 6;
		margin-top: -12px;
	}

	ol.seven {
		counter-reset: seven;
		list-style-type: none;
	}

	ol.seven > li:before {
		position: absolute;
		counter-increment: seven;
		content: '(' counter(seven, decimal) ')';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	ol.eight {
		counter-reset: eight;
		list-style-type: none;
	}

	ol.eight > li:before {
		position: absolute;
		counter-increment: eight;
		content: '(' counter(eight, lower-alpha) ')';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	ol.nine {
		counter-reset: nine;
		list-style-type: none;
	}

	ol.nine > li:before {
		position: absolute;
		counter-increment: nine;
		content: '(' counter(nine, lower-roman) ')';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	ol.ten {
		counter-reset: ten;
		list-style-type: none;
	}

	ol.ten > li:before {
		position: absolute;
		counter-increment: ten;
		content: counter(ten, upper-alpha) '.';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	ol.eleven {
		counter-reset: eleven;
		list-style-type: none;
	}

	ol.eleven > li:before {
		position: absolute;
		counter-increment: eleven;
		content: counter(eleven, decimal) '.';

		margin-left: -2.5em;
		margin-right: 1.5em;
	}

	#intro{
		width:100%;
		display:block;
	}
	ol.twelve {
		counter-reset: twelve;
		list-style-type: none;
	}

	ol.twelve > li:before {
		position: absolute;
		counter-increment: twelve;
		content:  counter(twelve, lower-alpha) ')';

		margin-left: -2.5em;
		margin-right: 1em;
	}

	.li-left-padding > li{
		padding-left: 1.25rem;
	}

	.partnerTitle {
		text-align: center;
	}

	.partnerTitleName {
		font-weight: bold;
		text-align: center;
		display:block;
	}

	.partnerTitleRight {
		text-align: right;
	}

	.diag {
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' version='1.1' width='100%' height='auto' viewBox='0 0 100 100'><line y2='0' x2='100' y1='0' x1='0' stroke='black' fill='black' stroke-width='1px'/><line x1='100' y1='0' x2='0' y2='100' stroke='black' fill='black' stroke-width='0.5px'/><line y2='100' x2='100' y1='100' x1='0' stroke='black' fill='black' stroke-width='1px'/></svg>");
		background-repeat: no-repeat;
		background-position: center center;
		height: 100%;
	}

	.align-center {
		text-align: center;
	}

	.bold {
		font-weight: bold;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.andList > li:nth-last-child(2)::after {
		content: '; and';
	}

	.andList > li:last-child::after {
		content: '.';
	}

	.andList > li:not(:nth-last-child(2)):not(:last-child)::after {
		content: ';';
	}
		
	.andList > li:not(:nth-last-child(2)):not(:last-child):has(> ol)::after {
		content: '' !important;
	}

	.period-andList > li:nth-last-child(2)::after {
		content: '; and';
	}

	.period-andList > li:last-child::after {
		content: '.';
	}

	.period-andList > li:last-child:has(> ol)::after {
		content: '' !important;
	}

	.period-andList > li:not(:nth-last-child(2)):not(:last-child):not(span)::after {
		content: ';';
	}

	.semiColon-andList > li:nth-last-child(2)::after {
		content: '; and';
	}

	.semiColon-andList > li:last-child::after {
		content: ';';
	}

	.semiColon-andList > li:not(:nth-last-child(2)):not(:last-child)::after {
		content: ';';
	}
		
	.semiColon-andList > li:not(:nth-last-child(2)):not(:last-child):has(> ol)::after {
		content: '' !important;
	}

	.period-orList > li:nth-last-child(2)::after {
		content: '; or';
	}

	.period-orList > li:not(:nth-last-child(2)):not(:last-child)::after {
		content: ';';
	}
	
	.period-orList > li:not(:nth-last-child(2)):not(:last-child):has(> ol)::after {
		content: '' !important;
	}

	.period-orList > li:last-child::after {
		content: '.';
	}

	/* SIMILAR TO ANDLIST, BUT INSTEAD OF SEMICOLON, USE A COLON; DOESN'T USE AN '; and' FOR THE SECOND-LAST ITEM */
	.colonList > li:not(:last-child)::after {
		content: ':';
	}
	.colonList > li.no-colon:not(:last-child)::after {
		content: '';
	}

	.colonList > li:last-child::after {
		content: '.';
	}

	/* SIMILAR TO INNER-ANDLIST, BUT FOR LISTS THAT ARE NESTED (WITH 3 OR MORE ITEMS); COMBO OF ANDLIST AND INNER LIST */

	/* not the last child, not the but only the first child IF it's also the second to last child */
	.doubleAnd-semiColonList > li:nth-last-child(n-2)::after {
		content: ';';
	}

	.doubleAnd-semiColonList > li:not(:first-child):nth-last-child(2)::after {
		content: '; and';
	}

	.doubleAnd-semiColonList > li:last-child::after {
		content: '; and';
	}

	.colon-semiColon-andList > li:first-child::after {
		content: ':';
	}
	.colon-semiColon-andList > li.no-colon:first-child::after {
		content: '';
	}

	.colon-semiColon-andList > li:nth-last-child(2)::after {
		content: '; and';
	}

	.colon-semiColon-andList > li.no-colon:nth-last-child(2)::after {
		content: '';
	}

	.colon-semiColon-andList > li:last-child::after {
		content: '.';
	}

	.colon-semiColon-andList
		> li:not(:nth-last-child(2)):not(:last-child):not(:first-child)::after {
		content: ';';
	}

	.offerToPurchaseList > li:first-child::after {
		content: ';';
		margin-left: -4px;
	}


	.offerToPurchaseList > li:not(:nth-child(2)):nth-last-child(2)::after {
		content: '; and';
		margin-left: -4px;
	}

	.offerToPurchaseList > li:last-child::after {
		content: '.';
		margin-left: -4px;
	}
	.marker-green{
		background-color:rgb(25, 156, 25)
	}
	.marker-yellow{
		background-color:#cac407
	}
	
	blockquote{
		border-left: 5px solid #ccc;
		font-style: italic;
		margin-left: 0;
		margin-right: 0;
		overflow: hidden;
		padding-left: 1.5em;
		padding-right: 1.5em;
	}
	.text-tiny{
		font-size: 0.7em;
	}
	.text-small{
		font-size: 0.85em;
	}
	.text-big{
		font-size: 1.4em;
	}
	.text-huge{
		font-size: 1.8em;
	}
	.align-bottom {
		vertical-align: bottom !important;
	}
	.space-bottom
	{
		margin-bottom: 5px;
	}
	.table-signature tr { line-height: 20px !important; }
	.table-signature tr td p{
		margin-bottom: 0px !important;
		margin-top: 0px !important;
	}
	/* start WAIVER OF INDEPENDENT LEGAL ADVICE page in new page */
	.start-new-page {
		page-break-before: always;
		padding-top: 2rem;
	}
	.waiver-name{
		margin-top: 0 !important;
		margin-bottom: 1rem !important;
	}
	.table-pastel-blue{
		background-color: #9CB6BD !important;
	}
	</style>
    `;

export default {
	name: 'DownloadWrapper',
	components: {
		Preview,
	},
	data() {
		return {
			showWatermark: false,
			additionalStyles,
			boostrapStyles:
				'<link rel="stylesheet" href="https://firebasestorage.googleapis.com/v0/b/divii-prod.appspot.com/o/assets%2Fbootstrap-table-print.min.css?alt=media&token=68f4ff5e-22c0-4476-b10e-070d94df9514">',
		};
	},
	mounted() {
		this.showWatermark = this.$route.query.draft === 'true' ? true : false;
		this.loadAgreementData();
		analytics.logEvent('agreement_downloaded');
		analytics.logEvent('screen_view', {
			firebase_screen: 'download',
			firebase_screen_class: 'DownloadWrapper',
		});
	},
	computed: {
		...mapState(['agreement', 'editedAgreement', 'user']),
	},
	methods: {
		async loadAgreementData() {
			await Promise.all([
				this.$store.dispatch('fetchAgreement', {
					id: this.$route.params.id,
				}),
				this.$store.dispatch('fetchEditedAgreement', {
					id: this.$route.params.id,
				}),
			]);
			this.downloadDocument();
		},
		async downloadDocument() {
			const filename = this.filename(this.$route.query);
			document.title = filename;
			const content = document.getElementById('preview').innerHTML;
			const doc = {
				document_content: `${this.boostrapStyles}${this.additionalStyles}${content}`,
				type: 'pdf',
				name: filename,
				// hosted: false,
			};
			try {
				const { blob, success } = await this.getDocument(doc);
				if (!success) return;

				const buff = Buffer.from(blob, 'base64');
				const newBlob = new Blob([buff], {
					type: 'application/pdf',
				});
				const data = window.URL.createObjectURL(newBlob);
				let link = document.createElement('a');
				link.href = data;
				link.download = filename;
				link.click();

				// await this.fileUpload(newBlob, filename);
			} catch (error) {
				console.error(error);
			}
		},
		async getDocument(doc) {
			try {
				const { data } = await authenticatedRequest(
					'POST',
					'/download',
					{
						uid: this.user.uid,
						doc,
					}
				);
				return data;
			} catch (error) {
				console.error(error);
			}
		},
		async fileUpload(file, filename) {
			const agreementStorageRef = storage
				.ref()
				.child(`agreements`)
				.child(this.agreement.id)
				.child(filename);
			const metaData = {
				contentType: 'application/pdf',
				customMetadata: {
					name: filename,
					// size: file.size,
					type: 'application/pdf',
				},
			};
			try {
				await agreementStorageRef.put(file, metaData);
			} catch (error) {
				console.error(error);
				this.error = 'Something went wrong';
			}
		},
	},
	mixins: [filename],
};
</script>

<template>
	<fragment>
		<preview
			:filename="filename(this.$route.query)"
			:preview-contents="editedAgreement"
			:showWatermark="showWatermark"
		/>
	</fragment>
</template>
